import {Page_Unit_Monitoring} from "../pages/units/Page_Unit_Monitoring";
import {Page_EnvCompare} from "../pages/Page_EnvCompare";
import {
    PermissionAccessLevel_Pairing,
    PermissionAccessLevel_PmArtifacts,
    PermissionAccessLevel_PmConfigs,
    PermissionAccessLevel_PmDeploy,
    PermissionAccessLevel_Units,
    PermissionKey_Pairing,
    PermissionKey_PmArtifacts,
    PermissionKey_PmConfigs,
    PermissionKey_PmDeploy,
    PermissionKey_Units
} from "@app/ir-q-app-common/types/legacy/sp-permissions";
import {PermissionsModule} from "@modules/PermissionsModule";
import {RoutingModule} from "@intuitionrobotics/thunderstorm/frontend";
import {Page_DeviceManager} from "../pages/pairing/Page_DeviceManager";
import Page_UnitPairing from "../pages/pairing/Page_DevicePairing";
import {Page_UnitsWrapper} from "../pages/units/Page_Units";
import {Page_PM_iframe} from "../pages/package-manager/Page_PM_iframe";
import Tab_PermissionsGroup from "../pages/permissions/Tab_PermissionsGroup";
import Tab_UsersToGroup from "../pages/permissions/Tab_UsersToGroup";
import {PermissionAccessLevel_Permissions, PermissionKey_Permissions} from "@app/ir-q-app-common/types/permissions";
import {Page_Overrides} from "../pages/overrides/Page_Overrides";
import {PermissionsFE} from "@intuitionrobotics/permissions/frontend";
import {Page_Preinstalls} from "../pages/preinstalls/Page_Preinstalls";
import {Page_Home} from "../pages/Page_Home";
import {Component_RefurbishBackground} from "../pages/units/components/Component_RefurbishBackground";
import {Page_ActivationsList} from "../pages/pairing/Page_ActivationsList";
import {Page_Activation} from "../pages/pairing/Page_Activation";
import {Page_Admin} from "../pages/pairing/Page_Admin";
import {Page_Convo} from "../pages/convo/Page_Convo";
import {Page_Contacts_iframe} from "../pages/contacts/Page_Contacts_iframe";

export const checkPermissions = <T extends { [s: number]: string }>(accessLevels: T, accessLevel: T[keyof T], permissionKey: string, defaultValue?: number) => (): boolean => {
    const _myPermissions = PermissionsModule.getMyPermissions();
    let myPermissions: { [feature: string]: string } = {};
    if (_myPermissions && _myPermissions.permissions)
        myPermissions = _myPermissions.permissions;

    const devPermissions = myPermissions[permissionKey] || defaultValue;

    if (!devPermissions)
        return false;

    // @ts-ignore
    return accessLevels[devPermissions] >= accessLevel;
};
export const Route_Home = "home";
export const Route_GptConvo = "gpt-convo";
export const Route_Convo = "conversation_page";
export const Route_PM = "package-manager";
export const Route_Monitoring = "monitoring";
export const Route_Activation = "activation";
export const Route_ActivationsList = "activation-list";
export const Route_Overrides = "overrides";
export const Route_UnitMonitoring = "unit-monitoring";
export const Route_UnitContact = "unit-contact";
export const Route_UnitPairing = "unit-pairing";
export const Route_UnitRepairing = "unit-repairing";
export const Route_UnitRefurbish = "unit-refurbish";
export const Route_PermissionsGroups = "permissions-groups";
export const Route_PermissionsUsers = "permissions-users";
export const Route_EnvCompare = "env-compare";

export const Url_SupportAdmin = "/v1/support/admin";
export const Url_ActivationClone = "/v1/rs/trigger";
export const Url_SupportSoftwareOnly = "/v1/support/software-only";
export const Url_AllPermitted = "/v1/support/all-permitted";
export const Url_SupportTeam = "/v1/support/team";
export const Url_SupportRemoteControl = "/v1/support/remote-control";
export const Url_SupportRefurbishUnit = "/v1/support/refurbish-unit";
export const Url_UpdateEnv = "/v1/elliq/update-env";
export const Route_Preinstalls = "preinstalls";
export const Route_DeviceManager = "device-manager";
export const Route_Admin = "admin";

export const UrlsToLoad = [
    Url_SupportAdmin,
    Url_SupportTeam,
    Url_SupportRemoteControl,
    Url_SupportRefurbishUnit,
    Url_UpdateEnv
];

export const checkPackageManagerVisibility = () => (
    checkPermissions(PermissionAccessLevel_PmConfigs, PermissionAccessLevel_PmConfigs.Read, PermissionKey_PmConfigs)() ||
    checkPermissions(PermissionAccessLevel_PmDeploy, PermissionAccessLevel_PmDeploy.Read, PermissionKey_PmDeploy)() ||
    checkPermissions(PermissionAccessLevel_PmArtifacts, PermissionAccessLevel_PmArtifacts.Read, PermissionKey_PmArtifacts)()
);

export const registerRoutes = () => {
    RoutingModule.clearRoutes();

    RoutingModule.addRoute(Route_Monitoring, "/monitoring", Page_UnitsWrapper)
        .setLabel("Monitoring")
        .setVisible(checkPermissions(PermissionAccessLevel_Units, PermissionAccessLevel_Units.Read, PermissionKey_Units));

    RoutingModule.addRoute(Route_Overrides, "/overrides", Page_Overrides)
        .setVisible(() => !!PermissionsFE.doesUserHavePermissions(Url_SupportAdmin));

    RoutingModule.addRoute(Route_UnitContact, "/unit/contact", Page_Contacts_iframe);
    RoutingModule.addRoute(Route_UnitMonitoring, "/unit/monitoring", Page_Unit_Monitoring);
    RoutingModule.addRoute(Route_EnvCompare, "/env-compare", Page_EnvCompare);

    RoutingModule.addRoute(Route_UnitPairing, "/unit/pairing", Page_UnitPairing)
        .setVisible(checkPermissions(PermissionAccessLevel_Pairing, PermissionAccessLevel_Pairing.Pair, PermissionKey_Pairing));

    RoutingModule.addRoute(Route_Activation, "/unit/activate", Page_Activation)
        .setLabel("Activate")
        .setVisible(checkPermissions(PermissionAccessLevel_Pairing, PermissionAccessLevel_Pairing.Pair, PermissionKey_Pairing));

    RoutingModule.addRoute(Route_ActivationsList, "/unit/activations", Page_ActivationsList)
        .setLabel("Activation List");

    RoutingModule.addRoute(Route_UnitRepairing, "/unit/repairing", Page_UnitPairing)
        .setVisible(checkPermissions(PermissionAccessLevel_Pairing, PermissionAccessLevel_Pairing.Pair, PermissionKey_Pairing));

    RoutingModule.addRoute(Route_UnitRefurbish, "/unit/refurbish", Component_RefurbishBackground);

    // RoutingModule.addRoute(Route_UserLogin, "/login", Page_Login).setLabel("Login");

    RoutingModule.addRoute(Route_PermissionsGroups, "/permissions/groups", Tab_PermissionsGroup)
        .setVisible(
            checkPermissions(PermissionAccessLevel_Permissions, PermissionAccessLevel_Permissions.WriteGroups, PermissionKey_Permissions));

    RoutingModule.addRoute(Route_PermissionsUsers, "/permissions/users", Tab_UsersToGroup)
        .setVisible(
            checkPermissions(PermissionAccessLevel_Permissions, PermissionAccessLevel_Permissions.Read, PermissionKey_Permissions));

    RoutingModule.addRoute(Route_PM, "/package-manager", Page_PM_iframe).setLabel("Package Manager")
        .setVisible(checkPackageManagerVisibility);

    RoutingModule.addRoute(Route_Preinstalls, "/preinstalls", Page_Preinstalls)
        .setLabel("Preinstalls")

    RoutingModule.addRoute(Route_DeviceManager, "/device-manager", Page_DeviceManager)
        .setLabel("Device Manager")

    RoutingModule.addRoute(Route_Admin, "/admin", Page_Admin)
        .setLabel("Admin")


    RoutingModule.addRoute(Route_Convo, "/convo", Page_Convo).setLabel("Convo")
    RoutingModule.addRoute(Route_Home, "/", Page_Home).setLabel("Home");

};

