import {_keys, compareVersions, currentTimeMillies, Day, Minute, Module} from "@intuitionrobotics/ts-common";
import {DeviceType, Unit} from "@app/ir-q-app-common/types/units";
import {AppPackage_PM, AppPackage_Strickland, PackageManager_Install, Response_SendPushToAndroid} from "@app/ir-q-app-common/types/push-messages";
import {DB_PushMessage, DB_PushMessageAndEnv, Response_ListPushAndroid} from "@app/ir-q-app-common/types/fb-push-messages";
import {ThunderDispatcher, ToastModule, XhrHttpModule} from "@intuitionrobotics/thunderstorm/frontend";
import {HttpMethod} from "@intuitionrobotics/thunderstorm";
import {ApiCancelPush, ApiPushDevicesList, ApiPushToAndroid, ApiPushToDevices, ApiSupportCancelManyPush} from "@app-sp/app-shared/api";
import {PC_UploadLogsViaStrickland, PCS_UploadLogs, PCT_UploadLogs, PushCommand, pushMessages} from "@app/ir-q-app-common/types/push-commands";
import {Request_PushToAndroid, Request_PushToDevices} from "@app-sp/app-shared/push-messages";
import {OnUnload, PackageManagerModule} from "@modules/package-manager/PackageManagerModule";
import {Product} from "@app/ir-q-app-common/types/products";
import {UnitsModule} from "@modules/UnitsModule";
import {Elliq_ProductKey} from "@app/ir-q-app-common/shared/consts";
import {KasperoPushCommands} from "@app/ir-q-app-common/types/api";
import {UnitsManagerModule} from "@modules/UnitsManagerModule";

export const RequestKey_PushMessage = "PushMessage";
export const RequestKey_FetchPushList = "push-list";
export const RequestKey_FetchPushCommands = "push-commands";
export const RequestKey_CancelPushMessage = "cancel-push";

type UnitAndDevice = { unit: Unit, deviceType: DeviceType }

export interface OnPushListReturned {
    __onPushListReturned(response: Response_ListPushAndroid): void;
}

export interface OnCancelPush {
    __onCancelPush: (mid: string) => void
}

export interface OnRedoPush {
    __onRedo: (mId?: string) => void
}

const dispatch_unload = new ThunderDispatcher<OnUnload, '__onUnload'>('__onUnload');
const dispatch_onRedoPush = new ThunderDispatcher<OnRedoPush, '__onRedo'>('__onRedo');

export type PushDataAndTimestamp = {
    data: Response_ListPushAndroid
    timestamp: number
};

export type PushParams = {
    type: string,
    data: string,
    packageName: string,
    ttl?: number
}

export type BatchActionsType = {
    key: string,
    label: string,
    devices?: DeviceType[],
    action: (units: Unit[], params?: any) => void,
    params?: any
    allowIndirectClosing?: boolean
}

export const actionDialogGenerator: { [key: string]: (...args: any) => BatchActionsType } = {
    installNow: (label: string = "Install Now") => ({
        key: "Install",
        label: label,
        params: {},
        action: (units: Unit[], params: { devices: DeviceType[], ttl?: number }) => {
            if (params.devices.length === 0)
                return ToastModule.toastError('Select at least a device to install');

            PushMessagesModule.pushCommandByVersionToDevice(units, params.devices, PackageManager_Install.type, "3.8.0", params.ttl);
        }
    }),
    rebootDevice: (devices: DeviceType[], command: PushCommand, label: string = "Reboot Device") => ({
        key: `${command.key}-${devices.join("-")}`,
        label: label,
        params: {},
        action: (units: Unit[], params: { devices: DeviceType[], ttl?: number }) => {
            if (params.devices.length === 0)
                return ToastModule.toastError('Select at least a device to reboot');

            PushMessagesModule.pushCommandByVersionToDevice(units, params.devices, command.key, "3.8.0", params.ttl);
        }
    }),
    generalPush: (devices: DeviceType[], label: string) => ({
        key: `GeneralPush-${devices.join("-")}`,
        label,
        devices,
        params: {},
        allowIndirectClosing: false,
        action: (units: Unit[], params: PushParams) => {
            PushMessagesModule.pushMessageToUnits(units, params.type, params.data, devices, params.packageName, params.ttl);
        }
    }),
    specificPush: (devices: DeviceType[], command: PushCommand, label?: string) => {
        const params: { [key: string]: any } = {};
        if (command.data)
            params.data = command.data;
        if (command.targetPackage)
            params.packageName = command.targetPackage;
        return ({
            key: `${command.key}-${devices.join("-")}`, // to make sure we have a unique key for each command
            label: label || command.label,
            devices,
            params,
            action: (units: Unit[], _params: PushParams) => {
                PushMessagesModule.pushMessageToUnits(units, command.key, _params.data, devices, _params.packageName, _params.ttl);
            }
        });
    },
    uploadLogs: (devices: DeviceType[], label: string = "Upload Logs") => ({
        key: "UploadLogs",
        label,
        params: {},
        action: (units: Unit[], params: PushParams) => {
            devices.includes("som") && PushMessagesModule.pushCommandByVersionToDevice(units, ["som"], PC_UploadLogsViaStrickland.key, "3.0.0", params.ttl, PCS_UploadLogs.targetPackage, PCS_UploadLogs.key);
            devices.includes("tablet") && PushMessagesModule.pushCommandByVersionToDevice(units, ["tablet"], PC_UploadLogsViaStrickland.key, "3.0.0", params.ttl, PCT_UploadLogs.targetPackage, PCT_UploadLogs.key);
        }
    }),
    cancelQueuedMessages: (devices: DeviceType[], label: string = "Cancel queued messages") => ({
        key: "CancelQueuedMessages",
        label,
        params: {},
        action: (units: Unit[]) => {
            PushMessagesModule.cancelManyPushMessage(units);
        }
    })
}


class PushMessagesModule_Class
    extends Module {

    private pushCommands?: PushCommand[];
    private pushMap: { [key: string]: PushDataAndTimestamp | undefined } = {};
    private dispatcher_onPushListReturned = new ThunderDispatcher<OnPushListReturned, "__onPushListReturned">("__onPushListReturned");

    fetchPushCommands = () => {
        XhrHttpModule
            .createRequest<KasperoPushCommands>(HttpMethod.GET, RequestKey_FetchPushCommands)
            .setRelativeUrl(`/v1/push/commands`)
            .setLabel(`Fetching list of push commands... `)
            .setOnError(`Error getting list of push commands`)
            .execute(async (response: PushCommand[]) => {
                this.pushCommands = response;
            });
    };

    fetchPushList = (unit: Unit): any => {
        const deviceIds = UnitsManagerModule.getDevicesIdsByUnits([unit], ['som', 'tablet'], true);
        const fromTimestamp: number = this.getPushMapElement(unit)?.timestamp || (currentTimeMillies() - 7 * Day);
        // up to when I got data...next call should start from here
        const timestamp = fromTimestamp;
        XhrHttpModule
            .createRequest<ApiPushDevicesList>(HttpMethod.POST, RequestKey_FetchPushList)
            .setJsonBody({deviceIds, fromTimestamp})
            .setTimeout(Minute)
            .setRelativeUrl(`/v1/push/devices/list`)
            .setLabel(`Fetching list of push commands for unit ${unit.unitId}`)
            .setOnError(`Error getting list of push commands for unit ${unit.unitId}`)
            .execute(async (response: Response_ListPushAndroid) => {
                this.upsertPushListResponse(response, unit, timestamp);
            });
    };

    upsertPushListResponse = (response: Response_ListPushAndroid, unit: Unit, timestamp?: number) => {
        const shas = _keys(response);
        const oldData = this.pushMap[`${unit.product}/${unit.unitId}`]?.data;
        if (oldData) {
            const oldKeys = _keys(oldData);
            oldKeys.forEach(k => {
                if (!shas.find(_k => _k === k))
                    shas.push(k);
            });
        }

        const data = shas.reduce((carry: Response_ListPushAndroid, el) => {
            const pushs = response[el] || [];
            const oldPushes = this.pushMap[`${unit.product}/${unit.unitId}`]?.data?.[el];
            if (oldPushes)
                this.upsert(pushs, oldPushes);

            carry[el] = pushs;
            return carry;
        }, {});

        this.pushMap[`${unit.product}/${unit.unitId}`] = {
            data,
            timestamp: timestamp || currentTimeMillies()
        };
        this.dispatcher_onPushListReturned.dispatchUI(response);
    };

    getPushList = (unit: Unit): Response_ListPushAndroid | undefined => this.getPushMapElement(unit)?.data;

    private getPushMapElement = (unit: Unit) => this.pushMap[`${unit.product}/${unit.unitId}`];

    private upsert(pushs: DB_PushMessageAndEnv[], oldPushes: DB_PushMessageAndEnv[]): void {
        oldPushes.forEach(om => {
            if (pushs.find(m => m.mId === om.mId))
                return;

            pushs.unshift(om);
        });
    }

    pushCommandByVersionToDevice = (units: Unit[], _devices: DeviceType[], pushCommand: string, minVersion: string, ttl?: number, prevPackage: string = AppPackage_PM, prevCommand?: string) => {
        const packageToUnits: { [packageName: string]: UnitAndDevice[] } = {
            [prevPackage]: [],
            [AppPackage_Strickland]: []
        }
        units.forEach(unit => {
            _devices.forEach(device => {
                const version = UnitsModule.getVersion(unit.unitId, device);
                if (!version)
                    return packageToUnits[prevPackage].push({unit, deviceType: device})

                if (compareVersions(version, minVersion) > 0)
                    return packageToUnits[prevPackage].push({unit, deviceType: device})
                else
                    return packageToUnits[AppPackage_Strickland].push({unit, deviceType: device})
            });
        })

        const pmUnits = packageToUnits[prevPackage].map(u => u.unit);

        if (pmUnits.length > 0)
            this.pushMessageToUnits(pmUnits, prevCommand || pushCommand, undefined, _devices, prevPackage, ttl)

        const stricklandUnits = packageToUnits[AppPackage_Strickland].map(u => u.unit);
        if (stricklandUnits.length > 0)
            this.pushMessageToUnits(stricklandUnits, pushCommand, undefined, _devices, AppPackage_Strickland, ttl)

    };

    pushMessageToUnits = (units: Unit[], type: string, data: string | undefined, _devices: DeviceType[], packageName?: string, ttl?: number) => {
        if (!_devices.length) {
            ToastModule.toastError("No devices selected");
            return;
        }

        const deviceIds = UnitsManagerModule.getDevicesIdsByUnits(units, _devices);

        const bodyObject: Request_PushToDevices = {deviceIds, type, ttl};
        if (packageName)
            bodyObject.packageName = packageName;
        if (data !== undefined)
            bodyObject.data = data;

        XhrHttpModule
            .createRequest<ApiPushToDevices>(HttpMethod.POST, RequestKey_PushMessage)
            .setRelativeUrl(`/v1/push/send-to-devices`)
            .setJsonBody(bodyObject)
            .setTimeout(Minute)
            .setLabel(`Push message now to units: ${units.map(u => u.unitId).join()} ...`)
            .setOnSuccessMessage(
                `${type} push message to ${packageName} was sent successfully to ${_devices.join(" and ")} of unit${units.length > 1 ? 's' : ''} ${units.map(unit => unit.unitId).join(", ")}`)
            .setOnError(() => {
                ToastModule.toastError(`Error sending push message`);
                dispatch_unload.dispatchUI(false);
            })
            .execute(() => {
                dispatch_unload.dispatchUI(false);
            });
    };

    pushMessageToDevice = (unitId: string, deviceId: string, type: string, data?: string, packageName?: string, ttl?: number, mIdToUnload?: string) => {
        const bodyObject: Request_PushToAndroid = {deviceId, type, ttl};
        if (packageName)
            bodyObject.packageName = packageName;
        if (data !== undefined)
            bodyObject.data = data;

        XhrHttpModule
            .createRequest<ApiPushToAndroid>(HttpMethod.POST, RequestKey_PushMessage)
            .setRelativeUrl(`/v1/push/send`)
            .setJsonBody(bodyObject)
            .setLabel(`Push message now to device: ${deviceId} ...`)
            .setOnError(() => {
                ToastModule.toastError(`Error sending push message`);
                dispatch_onRedoPush.dispatchUI(mIdToUnload);
            })
            .execute((resp: Response_SendPushToAndroid) => {
                this.logInfo(`Sent message with mId: ${resp.mId}${mIdToUnload ? ` which is a Re-Do of mId: ${mIdToUnload}` : ''}`)

                dispatch_onRedoPush.dispatchUI(mIdToUnload);
            });
    };

    getPushMessages = (devices: DeviceType[]) => {
        if (devices.length === 1)
            return this.getPushMessagesForDevice(devices[0])

        return this.getPushCommands();
    };

    private getPushCommands() {
        if (this.pushCommands)
            return this.pushCommands;

        return pushMessages;
    }

    getProduct(product: string = Elliq_ProductKey): Product | undefined {
        const productsCollection = PackageManagerModule.getProductsCollection();
        if (!productsCollection)
            return;

        return productsCollection.find(p => p.key === product);
    }

    getPushMessagesForDevice = (deviceType: DeviceType, productKey: string = Elliq_ProductKey) => {
        const prod = this.getProduct(productKey)
        if (!prod)
            return [];

        const device = prod.devices[deviceType];
        if (!device)
            return [];

        return this.getPushCommands().filter(m => {
            if (m.deviceType)
                return deviceType === m.deviceType;

            return device.apps.includes(m.targetPackage)
        });
    };

    devicePushCanRunOn = (push: PushCommand, productKey: string = Elliq_ProductKey): DeviceType[] => {
        const prod = this.getProduct(productKey)
        if (!prod)
            return [];

        return Object.keys(prod.devices).filter(d => {
            return prod.devices[d].apps.includes(push.targetPackage)
        }) as DeviceType[];
    };

    cancelPushMessage = (unitId: string, product: string, message: DB_PushMessageAndEnv) => {
        const {mId, env: pushEnv} = message
        XhrHttpModule
            .createRequest<ApiCancelPush>(HttpMethod.POST, RequestKey_CancelPushMessage)
            .setRelativeUrl("/v1/push/cancel")
            .setLabel(`Canceling push message for unit ${unitId}`)
            .setJsonBody({mId, pushEnv})
            .setOnError(() => {
                ToastModule.toastError(`Failed canceling push message for unit ${unitId}`);
                new ThunderDispatcher<OnCancelPush, "__onCancelPush">("__onCancelPush").dispatchUI(mId)
            })
            .setOnSuccessMessage(`Push message for unit ${unitId} cancelled successfully`)
            .execute(response => {
                this.upsertPushListResponse({[message.deviceId]: [{...response, env: message.env}]}, {unitId, product});
                new ThunderDispatcher<OnCancelPush, "__onCancelPush">("__onCancelPush").dispatchUI(mId)
            });
    };

    cancelManyPushMessage = (units: Unit[]) => {
        XhrHttpModule
            .createRequest<ApiSupportCancelManyPush>(HttpMethod.POST, RequestKey_CancelPushMessage)
            .setRelativeUrl("/v1/push/cancel-many")
            .setLabel(`Canceling push messages for units ${units.map(unit => unit.unitId)}`)
            .setJsonBody({units})
            .setOnError(() => {
                ToastModule.toastError(`Failed cancel-many push messages for unit ${units.map(unit => unit.unitId)}`);
            })
            .setOnSuccessMessage(`Push messages for units ${units.map(unit => unit.unitId)} cancelled successfully`)
            .execute(response => {
            });
    };

    redoPush(m: DB_PushMessage, unitId: string) {
        const resp = this.getMessageDataFromDBMessage(m);
        if (!resp)
            return dispatch_onRedoPush.dispatchUI(m.mId);

        const {deviceId, type, data, packageName, ttl} = resp;
        this.pushMessageToDevice(unitId, deviceId, type, data, packageName, ttl, m.mId);
    }

    private getMessageDataFromDBMessage = (m: DB_PushMessage): {
        deviceId: string,
        type: string,
        data?: string,
        packageName?: string,
        ttl?: number
    } | void => {
        const {packageName, message} = m.data;
        try {
            const messageContent = JSON.parse(message);
            const {type, payload} = messageContent;
            return {deviceId: m.deviceId, type, packageName, data: payload, ttl: m.ttl}
        } catch (e) {
            this.logError(`Error parsing db push message with mid ${m.mId}, data.message is not an object but a ${typeof message}`)
        }
    }
}

export const PushMessagesModule = new PushMessagesModule_Class("PushMessagesModule");
