import * as React from "react";
import {BaseComponent, RoutingModule, ToastModule} from "@intuitionrobotics/thunderstorm/frontend";
import {getLastPolling, getShortEnvName, getStatus, InfoRenderer, renderInfo, renderTitle} from "@renderers/unit-info";
import {BaseRuntimeStatus, DeviceInfo, isAlive, SomInfo, TabletInfo} from "@app/ir-q-app-common/types/runtime-status";
import {PushCommand} from "@app/ir-q-app-common/types/push-commands";
import {Renderer_Command} from "@renderers/push-command";
import {marginTop} from "@styles/dynamic-styles";
import {COLOR_lightGreen, COLOR_lightRed, COLOR_purple} from "@styles/colors";
import {DeviceType} from "@app/ir-q-app-common/types/units";
import Panel_ArtifactData from "./Panel_ArtifactsData";
import {PmStatus} from "@app-sp/app-shared/package-manager";
import {UnitsModule} from "@modules/UnitsModule";
import {OpenDialog, renderPushMessageButton} from "./Page_Unit_Monitoring";
import {css} from "emotion";
import {icon__eyeS} from "./utils";
import {ExpandMore} from "@mui/icons-material";
import {Dialog_MoreCommands} from "./components/Dialog_MoreCommands";
import {Accordion, AccordionDetails, AccordionSummary} from "@mui/material";
import {Tree} from "../../../playground/Tree";
import {MyTreeRenderer} from "../overrides/Page_Overrides";
import {BugReportModule} from "@intuitionrobotics/bug-report/app-frontend/modules/BugReportModule";
import {StorageKey_UserEmail} from "@consts/common";
import {__stringify, Second} from "@intuitionrobotics/ts-common";
import {Platform_Slack} from "@intuitionrobotics/bug-report/shared/api";
import {PackageManagerModule, RequestKey_FetchDeviceConfigFe} from "@modules/package-manager/PackageManagerModule";
import {actionDialogGenerator} from "@modules/PushMessagesModule";
import {Component_PushMessagesDialog} from "./components/Dialog_PushMessages";
import {SimpleLoader} from "@components/SimpleLoader";
import {OnRequestListener} from "@intuitionrobotics/thunderstorm";

interface Props {
    runtimeStatus?: BaseRuntimeStatus;
    serial?: string
    deviceType: DeviceType;
    unitId: string;
    product: string;
    label: string;
    primaryRenderers: InfoRenderer[];
    secondaryRenderers?: InfoRenderer[];
    primaryPushCommands?: PushCommand[];
    secondaryPushCommands?: PushCommand[];
    pmStatus?: PmStatus;
    preview?: boolean;
}

type State = {
    raw: boolean;
    loading?: boolean;
}
const noRSClass = css({
    width: "100%",
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    padding: 20,
    fontWeight: 900,
    fontSize: 30
});

class Panel_DeviceStatus
    extends BaseComponent<Props, State>
    implements OnRequestListener {
    private openDialog?: OpenDialog;

    constructor(props: Props) {
        super(props);
        this.state = {
            raw: false
        };
    }

    __onRequestCompleted = (key: string, success: boolean) => {
        switch (key) {
            case RequestKey_FetchDeviceConfigFe:
                this.requestFetchConfigFeCompleted();
                return;
        }
    }

    render() {
        return <div id={this.props.deviceType} className={`ll_v_l match_width`}>
            {this.renderDeviceStatus()}
            {this.renderActions()}
            {this.renderArtifactData()}
            {this.state.loading && <SimpleLoader overlay={true}/>}
        </div>;
    }

    requestConfigToBeInstalled = () => {
        if (!this.openDialog)
            return;

        this.setState({loading: true});
        PackageManagerModule.fetchDeviceConfigFe([{product: this.props.product, unitId: this.props.unitId}]);
    };

    requestFetchConfigFeCompleted = () => {
        this.setState({loading: false});
        if (this.openDialog !== "push message")
            return;

        const action = actionDialogGenerator.generalPush([this.props.deviceType], `Push message to ${this.props.deviceType}`);
        this.openDialog = undefined;
        Component_PushMessagesDialog.show([{unitId: this.props.unitId, product: this.props.product}], action);
    };

    private static backgroundColor(deviceInfo: DeviceInfo, deviceType: DeviceType) {
        const lastPolling = Math.max(getLastPolling(deviceInfo, deviceType) || 0, deviceInfo.timestamp?.millis || 0);
        const alive = isAlive(lastPolling);
        if (deviceInfo.manualShutdown && !alive)
            return COLOR_purple;
        return alive ? COLOR_lightGreen : COLOR_lightRed;
    }

    private renderDeviceStatus = () => {
        const {unitId} = this.props;
        const unitRuntimeStatus = this.props.runtimeStatus;
        const unitView = UnitsModule.getUnitView(unitId);
        let unitVersion = "";
        if (unitView) {
            const versions = UnitsModule.getDevicesVersions(unitId);
            const deviceType = this.props.deviceType;
            const version = versions[deviceType];
            if (version)
                unitVersion += `${version}`
        }
        const deviceInfo = unitRuntimeStatus?.[this.props.deviceType];
        if (!unitRuntimeStatus)
            return;

        if (!deviceInfo)
            return <div className={noRSClass}>No Runtime Status</div>

        const onboardingTime = UnitsModule.getOnboardingTimesByUnit({
            unitId: this.props.unitId,
            product: this.props.product
        })?.[this.props.deviceType]?.timestamp;

        const backgroundColor = Panel_DeviceStatus.backgroundColor(deviceInfo, this.props.deviceType);
        return <div
            id={`${this.props.deviceType}-mapping-content`}
            className={`match_width`}
            style={{
                boxSizing: "border-box",
                fontSize: this.props.preview ? 11 : "inherit",
                height: this.props.preview ? 575 : "unset",
                overflowY: "auto",
                padding: "0 5px",
                position: "relative",
                backgroundColor
            }}
        >
            <img
                src={icon__eyeS}
                width={20}
                style={{
                    position: "absolute",
                    right: 20
                }}
                className="clickable"
                alt="eye"
                onClick={() => this.setState(s => ({raw: !s.raw}))}/>
            {this.renderData(deviceInfo, onboardingTime, unitVersion)}
        </div>;
    };

    private renderDevicePanelTitle(unitVersion: string, environment: string, completeInfo: DeviceInfo) {
        const appStatusComponent = getStatus(this.props.deviceType, this.props.unitId)(completeInfo);
        return <>
            {renderTitle(`${unitVersion ? unitVersion + "," : null} ${getShortEnvName(environment)}`, appStatusComponent)}
        </>
    }

    private renderRuntimeStatusData = (onboardingTime?: number) => {
        const deviceInfo: DeviceInfo | undefined = this.props.runtimeStatus?.[this.props.deviceType];
        if (!deviceInfo || Object.keys(deviceInfo).length < 1)
            return <div className={noRSClass}>No Runtime Status</div>;
        const otherData = this.props.runtimeStatus?.[this.props.deviceType === "som" ? "tablet" : "som"];
        const completeInfo: DeviceInfo & { onboardingTime?: number } = {...deviceInfo, onboardingTime};
        return <div className={"ll_v_c"}>
            <div style={{padding: "0 10px", boxSizing: "border-box"}} className={"ll_v_c match_width"}>
                {this.props.primaryRenderers.map((R: InfoRenderer, idx) => {
                        return <RendererWithCatch key={idx}> <R
                            data={completeInfo}
                            deviceType={this.props.deviceType}
                            serial={this.props.serial}
                            otherData={otherData}
                        />
                        </RendererWithCatch>;
                    }
                )}
            </div>
            {
                this.props.secondaryRenderers && this.props.secondaryRenderers.length > 0 &&
                <div style={{padding: "5px 0"}} className={"ll_v_c match_width"}>
                    <Accordion className={"match_width"}>
                        <AccordionSummary expandIcon={<ExpandMore/>}>
                            <div style={{fontSize: "1rem", fontWeight: "bold"}}>Other Info</div>
                        </AccordionSummary>
                        <AccordionDetails style={{display: "flex", flexDirection: "column"}}>
                            {this.props.secondaryRenderers.map((R: InfoRenderer, idx) => <RendererWithCatch key={idx}>
                                    <R
                                        data={completeInfo}
                                        deviceType={this.props.deviceType}
                                        serial={this.props.serial}
                                        otherData={otherData}
                                    />
                                </RendererWithCatch>
                            )}
                        </AccordionDetails>
                    </Accordion>
                </div>
            }
        </div>
    }

    private renderData = (deviceInfo: SomInfo | TabletInfo, onboardingTime?: number, unitVersion: string = "") => {
        if (!this.state.raw) {
            const completeInfo: DeviceInfo & { onboardingTime?: number } = {...deviceInfo};
            completeInfo['onboardingTime'] = onboardingTime;
            return <>
                {this.renderDevicePanelTitle(unitVersion, deviceInfo.environment, completeInfo)}
                {this.renderRuntimeStatusData(onboardingTime)}
            </>;
        }

        return <div>
            <Tree
                id="tree"
                renderer={MyTreeRenderer}
                root={deviceInfo}
                hideRootElement={true}
                nodesState={Tree.recursivelyExpand(deviceInfo, (key: string, value: any, level: number) => {
                    return level < 1;
                })}
            />
        </div>;
    };

    private renderActions = () => {
        const pushCommands = this.props.primaryPushCommands;
        if (!pushCommands)
            return;

        // const unit = this.getUnit();
        return <div
            id="woz-moved-to-push-commands"
            className={`ll_h_c ${marginTop(5)}`}
            style={{marginBottom: "2px", paddingLeft: "1px", flexWrap: "wrap"}}
        >
            {pushCommands.map(
                (pushCommand, index) => {
                    return <Renderer_Command
                        key={index}
                        command={pushCommand}
                        unit={{product: this.props.product, unitId: this.props.unitId}}
                        device={this.props.deviceType}
                    />;
                })
            }

            {renderPushMessageButton(`Push message to ${this.props.deviceType}`, undefined, () => {
                this.openDialog = "push message";
                this.requestConfigToBeInstalled();
            })}
            <Dialog_MoreCommands
                pushCommands={this.props.secondaryPushCommands}
                unitId={this.props.unitId}
                product={this.props.product}
                deviceType={this.props.deviceType}
                version={this.props.runtimeStatus?.som?.version_name}
            />
        </div>;
    };

    private renderArtifactData = () => {
        const pmStatus = this.props.pmStatus;
        if (!pmStatus)
            return;

        return <Panel_ArtifactData deviceStatus={pmStatus}/>;
    };
}

class RendererWithCatch extends React.Component<{ children: React.ReactNode }, { error: string }> {

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
        BugReportModule.sendBugReport(
            `Support Crashed for user ${StorageKey_UserEmail.get()} at page: ${RoutingModule.getMyRouteKey()}`,
            "these logs were triggered by a UI failure\n" + __stringify(error.message), [Platform_Slack]);
        ToastModule.toastError(
            "Something wrong happened, check the console or call support. If you experience more issues please refresh the page",
            3 * Second);
    }

    static getDerivedStateFromError(error: any) {
        // Update state so the next render will show the fallback UI.
        BugReportModule.logWarning(error);
        return {error: __stringify(error)};
    }

    render() {
        if (this.state?.error)
            return renderInfo("Error", `Something went wrong rendering part of the runtime status`, COLOR_lightRed)

        return this.props.children;
    }
}

export default Panel_DeviceStatus;
