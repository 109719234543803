import * as React from 'react';
import {BaseComponent, TS_Checkbox, TS_Input} from '@intuitionrobotics/thunderstorm/frontend';
import {OnRequestListener} from '@intuitionrobotics/thunderstorm';
import {RequestKey_FetchUnitsFullList, RequestKey_GetIdentities, UnitsModule} from "@modules/UnitsModule";
import {DeviceIdentity, FullUnitConfig, Response_Identities} from '@app/ir-q-app-common/types/units';
import {SimpleLoader} from "@components/SimpleLoader";
import {compare} from "@intuitionrobotics/ts-common";
import {css} from "emotion";
import {KasperModule, RequestKey_DeleteDevice, RequestKey_PairUnit} from "@modules/KasperModule";
import {Component_Pair, DialogPairProps} from "../units/components/Dialog_Pair";
import {PairRequestsModule, RequestKey_ListPairRequests} from "@modules/PairRequestsModule";
import {Dialog_AddPairRequest} from "../units/components/Dialog_AddPairRequest";
import {Dialog_ListPairRequest} from "../units/components/Dialog_ListPairRequests";
import {ConfirmationModule} from "@modules/confirmation-modal-module";
import {CopyButton} from "../../renderers/CopyButton";
import {UnitContext} from "../../App";

type state = {
    selectedSom?: MyDeviceIdentity
    selectedTablet?: MyDeviceIdentity
    page: number
    filter: string
    loading: boolean
}

const inputStyle = css(
    {
        border: "1px solid grey",
        borderRadius: 5
    })

const btnStyle = css(
    {
        background: "orange",
        border: "1px solid grey",
        borderRadius: 10,
        height: 28,
        cursor: "pointer"
    })

enum TypeOfIdentity {
    Real = "Real",
    Request = "Request",
    Deleted = "Deleted"
}

type MyDeviceIdentity = DeviceIdentity & {
    typeOf: TypeOfIdentity,
    unitId: string | null
    when?: string
    who?: string
    timestamp?: number
    env?: string
}

export class Page_DeviceManager
    extends BaseComponent<{}, state>
    implements OnRequestListener {
    private maxPerPage: number = 100;

    __onRequestCompleted(key: string, success: boolean, requestData?: string): void {
        switch (key) {
            case RequestKey_DeleteDevice:
                UnitsModule.fetchDevices();
                break;
            case RequestKey_FetchUnitsFullList:
                this.forceUpdate()
                break;
            case RequestKey_PairUnit:
            case RequestKey_GetIdentities:
            case RequestKey_ListPairRequests:
                this.setState({loading: false})
        }
    }

    private headers: (keyof MyDeviceIdentity)[] = ['serial',
        'wifiMac',
        'sha256',
        'type',
        'typeOf',
        "version",
        "when",
        "who",
        "unitId"
    ];

    constructor(props: {}) {
        super(props);
        this.state = {
            page: 0,
            filter: "",
            loading: true
        };
        UnitsModule.fetchPairedUnitsImpl();
        UnitsModule.fetchDevices();
        PairRequestsModule.fetchPairRequests();
    }

    render() {
        const deviceIdentities = UnitsModule.getDeviceIdentities();
        if (this.state.loading)
            return <SimpleLoader overlay={true}/>


        return <UnitContext.Consumer>
            {units => {
                const dev = this.calculateDevicesThatAreNotUsed(Array.from(units.pairedUnits), deviceIdentities);

                return <div
                    className={"ll_v_c match_width"}
                    style={{
                        justifyContent: "center",
                        boxSizing: 'border-box',
                        padding: 10
                    }}
                >
                    {this.renderSearchAndPagination(dev)}
                    {this.getTable(dev)}
                </div>
            }}
        </UnitContext.Consumer> ;
    }

    private renderSearchAndPagination(dev: MyDeviceIdentity[]) {
        return <div
            className={'ll_h_c match_width'}
            style={{justifyContent: "space-between", padding: 10}}
        >
            <TS_Input
                style={{width: '80%', height: 28}}
                value={this.state.filter}
                onChange={value => this.setState({filter: value})}
                type={"text"}
                id={"filter"}
                placeholder={"Type to search"}
                className={inputStyle}
            />
            {this.renderDeleteDevice()}
            {this.renderPairButton()}
            {this.renderPagination(dev)}
            {this.renderListPairRequests()}
            {this.renderAddPairRequest()}
        </div>;
    }

    private renderAddPairRequest() {
        return <button
            onClick={() => {
                Dialog_AddPairRequest.show({})
            }}
            className={btnStyle}>
            {`Request`}
        </button>
    }

    private renderListPairRequests() {
        return <button
            onClick={() => {
                Dialog_ListPairRequest.show({})
            }}
            className={btnStyle}>
            {`List`}
        </button>
    }

    private getTable(dev: MyDeviceIdentity[]) {
        const tableStyle = css({
            border: "1px solid grey",
            borderCollapse: 'collapse',
            width: '100%'
        })
        const devices = dev.slice(this.maxPerPage * this.state.page, this.maxPerPage * (this.state.page + 1));
        if (this.state.page !== 0) {
            if (this.state.selectedSom)
                devices.unshift(this.state.selectedSom)

            if (this.state.selectedTablet)
                devices.unshift(this.state.selectedTablet)
        }

        return <div
            className={"ll_h_c match_width"}
            style={{justifyContent: "center"}}
        >
            <table className={tableStyle}>
                <thead>
                <tr>
                    {this.headers.map((header, i) => <th key={header}>{header}</th>)}
                </tr>
                </thead>
                <tbody>
                {devices.map((row, idx) => this.renderRow(row, idx))}
                </tbody>
            </table>
        </div>;
    }

    private renderPagination(dev: MyDeviceIdentity[]) {
        const devicesLength = dev.length
        const maxPagesPerLegend = 5;
        const delta = Math.floor(maxPagesPerLegend / 2);
        const maxPage = Math.floor(devicesLength / this.maxPerPage)
        const maxFirstPage = maxPage - maxPagesPerLegend + 1;
        const firstPage = this.state.page <= delta ? 0 : (this.state.page < maxFirstPage + delta ? this.state.page - delta : maxFirstPage);
        const howManyPages = Math.min(maxPage, maxPagesPerLegend);
        if (howManyPages === 0)
            return;

        const className = css`
          padding: 5px;
          border: 1px solid grey;
          border-radius: 10px;
          margin: 0 2px;
          cursor: pointer;
          min-width: 19px;
          display: flex;
          align-items: center;
          justify-content: center;

          &:hover {
            background: orange;
          }
        `;
        const cursorClass = css`
          ${className};
          font-size: 80%;
        `
        return <div className={"ll_h_c"}>
            <div
                className={cursorClass}
                onClick={() => this.setState(_state => ({page: Math.max(_state.page - 1, 0)}))}
            >{"<<"}
            </div>
            {Array(howManyPages)
                .fill(0)
                .map((e, i) => {
                    return <div
                        className={className}
                        style={this.state.page === firstPage + i ? {
                            backgroundColor: 'orange'
                        } : {}}
                        key={i}
                        onClick={() => this.setState({page: firstPage + i})}>
                        {firstPage + i + 1}
                    </div>;
                })}
            <div
                className={cursorClass}
                onClick={() => this.setState(_state => ({page: Math.min(_state.page + 1, maxPage)}))}
            >{">>"}
            </div>
        </div>;
    }

    calculateDevicesThatAreNotUsed = (unitIds: string[], deviceIdentities?: Response_Identities): MyDeviceIdentity[] => {
        if (!unitIds || !deviceIdentities)
            return []

        const shas = unitIds.reduce((toRet: { [k: string]: FullUnitConfig }, u: string) => {
            const identity = UnitsModule.getIdentity(u);
            if(!identity)
                return toRet;
            for (const sha of identity.sha256) {
                toRet[sha] = identity;
            }
            return toRet;
        }, {});

        const realMap = deviceIdentities.identities.reduce((acc:  MyDeviceIdentity[], id) => {
            const ret: MyDeviceIdentity = {
                ...id,
                typeOf: TypeOfIdentity.Real,
                unitId: shas[id.sha256]?.unitId || null,
                env: shas[id.sha256]?.comment
            };

            this.populateAudit(id, ret);
            if (this.isFilteredOut(ret))
                return acc;

            acc.push(ret);
            return acc;
        }, []);

        deviceIdentities.unpairedIdentities.reduce((acc: MyDeviceIdentity[], id) => {
            const ret: MyDeviceIdentity = {
                ...id,
                typeOf: TypeOfIdentity.Deleted,
                unitId: null
            };

            this.populateAudit(id, ret);
            if (this.isFilteredOut(ret))
                return acc;

            acc.push(ret);
            return acc;
        }, realMap);


        return deviceIdentities.requests.reduce((acc: MyDeviceIdentity[], id) => {
            const ret: MyDeviceIdentity = {...id, typeOf: TypeOfIdentity.Request, unitId: null};
            this.populateAudit(id, ret);
            if (!this.isFilteredOut(ret))
                acc.push(ret);

            return acc;
        }, Object.values(realMap)).sort((d1, d2) => {
            const is1Selected = this.isRowSelected(d1);
            const is2Selected = this.isRowSelected(d2);
            if (is1Selected && is2Selected)
                return d1.type === 'som' ? -1 : 1;
            if (is1Selected && !is2Selected)
                return -1;
            if (!is1Selected && is2Selected)
                return 1;
            if (d1.typeOf !== d2.typeOf)
                return d1.typeOf === TypeOfIdentity.Request ? -1 : 1;
            if (d1.unitId && !d2.unitId)
                return 1;
            if (!d1.unitId && d2.unitId)
                return -1;

            const d1T = d1._audit?.auditAt.timestamp;
            const d2T = d2._audit?.auditAt.timestamp;
            if (!d2T)
                return 1;
            if (!d1T)
                return d1.serial > d2.serial ? 1 : -1

            return d1T < d2T ? 1 : -1
        })
    };

    private populateAudit(id: DeviceIdentity, ret: MyDeviceIdentity) {
        if (!id._audit)
            return;

        ret.when = id._audit.auditAt.pretty;
        ret.who = id._audit.auditBy?.split("@")[0];
        ret.timestamp = id._audit.auditAt.timestamp;
    }

    private renderRow(rowValue: MyDeviceIdentity, idx: number) {
        const isSelected = this.isRowSelected(rowValue);
        const rowStyle = css({backgroundColor: isSelected ? "rgba(255,147,18,0.22)" : ""})
        return <tr key={idx} className={rowStyle}>
            {this.headers.map((h, i) => this.renderCell(rowValue, idx, h, i)
            )}
        </tr>;
    }

    private isRowSelected(rowValue: MyDeviceIdentity) {
        const selectedSom = this.isSomSelected(rowValue);
        const selectedTablet = this.isTabletSelected(rowValue);
        return selectedSom || selectedTablet;
    }

    private isTabletSelected(rowValue: MyDeviceIdentity) {
        return rowValue.type === 'tablet' && compare(this.state.selectedTablet, rowValue);
    }

    private isSomSelected(rowValue: MyDeviceIdentity) {
        return rowValue.type === 'som' && compare(this.state.selectedSom, rowValue);
    }

    private renderCell(obj: MyDeviceIdentity, rowIndex: number, columnKey: keyof MyDeviceIdentity, cellIndex: number) {
        const selectedSom = this.isSomSelected(obj);
        const selectedTablet = this.isTabletSelected(obj);
        const cellStyle = css({border: "1px solid grey", padding: 5, cursor: "pointer"})
        const onClick = () => {
            if (selectedSom)
                return this.setState({selectedSom: undefined})

            if (selectedTablet)
                return this.setState({selectedTablet: undefined})

            if (obj.type === 'som')
                return this.setState({selectedSom: obj});

            if (obj.type === 'tablet')
                return this.setState({selectedTablet: obj});
        };
        let checkbox = <></>;
        if (columnKey === 'serial') {
            checkbox = <TS_Checkbox<boolean>
                value={selectedSom || selectedTablet}
                checked={selectedSom || selectedTablet}
                onCheck={onClick}
                label={null}
            />
        }

        const objElement = obj[columnKey];
        return <td onClick={onClick} key={rowIndex + '_' + cellIndex} className={` ${cellStyle}`}>
            <div className={'ll_h_c'}>
                {checkbox}
                <div style={{
                    maxWidth: `${Math.floor(110 / this.headers.length)}vw`,
                    overflow: "hidden",
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis'
                }}>{String(obj[columnKey])}</div>
                {objElement && <CopyButton obj={objElement}/>}
            </div>
        </td>;
    }

    private isFilteredOut(ret: MyDeviceIdentity): boolean {
        const filter = this.state.filter.trim();
        if (!filter)
            return false;

        if (this.isRowSelected(ret))
            return false;

        const filters = filter.split(" ");
        for (const aFilter of filters) {
            const aFilterElements = aFilter.split(":");
            if (aFilterElements.length === 2 && aFilterElements[0] in ret) {
                const string = aFilterElements[0] as keyof MyDeviceIdentity;
                const retElement = ret[string];
                const toMatch = aFilterElements[1].toLowerCase();
                if (retElement !== undefined && toMatch && !`${retElement}`.toLowerCase().includes(toMatch))
                    return true;

            } else {
                if (!this.someValuesMatch(ret, aFilter))
                    return true;
            }
        }
        return false;
    }

    private someValuesMatch(ret: MyDeviceIdentity, aFilter: string) {
        return Object.values(ret).some(v => `${v}`.toLowerCase().includes(aFilter.toLowerCase()));
    }

    private renderDeleteDevice() {
        const somBarcode: MyDeviceIdentity | undefined = this.state.selectedSom;
        const tabletBarcode: MyDeviceIdentity | undefined = this.state.selectedTablet;
        const onlyOneDeviceSelected = (somBarcode && !tabletBarcode) || (tabletBarcode && !somBarcode);

        if (!onlyOneDeviceSelected)
            return;

        const selectedBarcode = this.state.selectedSom || this.state.selectedTablet;
        if (selectedBarcode?.typeOf !== TypeOfIdentity.Real)
            return;

        if (selectedBarcode.unitId)
            return;

        return <button
          onClick={() => {
              const params = {
                title: 'Are you sure you want to delete device?',
                bodyText: 'Are you sure you want to delete device' + "\n" + `serial: ${selectedBarcode?.serial}` + "\n" + `sha: ${selectedBarcode?.sha256}`,
                onSubmit: async () => KasperModule.deleteDevice(selectedBarcode?.sha256),
                submitButtonText: "Delete",
                width: 600
              };

              return ConfirmationModule.confirmationDialog(params)
        }}
          className={btnStyle}>
            {`Delete`}
        </button>
    }

    private renderPairButton() {
        const somBarcode: MyDeviceIdentity | undefined = this.state.selectedSom;
        const tabletBarcode: MyDeviceIdentity | undefined = this.state.selectedTablet;
        if (!somBarcode)
            return;

        const isSoftwareOnly = somBarcode && !tabletBarcode;
        return <button
            onClick={() => {
                const props: DialogPairProps = {
                    somBarcode,
                    tabletBarcode: tabletBarcode || KasperModule.getFakeTabletBarcodeForSO(somBarcode),
                    repair: !!this.state.selectedSom?.unitId || !!this.state.selectedTablet?.unitId,
                    softwareOnly: isSoftwareOnly
                };

                const unitId = somBarcode.unitId || tabletBarcode?.unitId;
                if (unitId) {
                    props.suggested = {
                        unitId,
                        env: somBarcode.env || tabletBarcode?.env
                    }
                }

                Component_Pair.show(props)
            }}
            className={btnStyle}>
            {`Pair${isSoftwareOnly ? " SO" : ""}`}
        </button>
    }
}
