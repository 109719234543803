import {Module} from "@intuitionrobotics/ts-common";
import {QueryParam_Email} from "@app/ir-q-app-common/types/auth";
import {QueryParam_JWT} from "@intuitionrobotics/user-account/shared/api";

type Config = {
    envName: string
    kasperoFeUrl: string
}

export class EnvironmentModule_Class
    extends Module<Config> {

    getKasperoFeUrl() {
        return this.config.kasperoFeUrl;
    }

    getOriginUrl() {
        return window.location.origin;
    }

    getQuery() {
        let query = window.location.search;
        if (query.startsWith("?"))
            query = query.substring(1);
        return query;
    }

    getPathname() {
        return window.location.pathname
    }

    getOrigin = () => {
        const jwtParam = `${QueryParam_JWT}=${QueryParam_JWT.toUpperCase()}`;
        const userEmailParam = `${QueryParam_Email}=${QueryParam_Email.toUpperCase()}`;
        return `${this.getOriginUrl()}${this.getPathname()}?${jwtParam}&${userEmailParam}&${this.getQuery()}`;
    };

    getEnvName() {
        return this.config.envName.toUpperCase();
    }

    isProd() {
        return this.getEnvName() === 'PROD';
    }

    isStaging() {
        return this.getEnvName() === 'STAGING';
    }
}

export const EnvironmentModule = new EnvironmentModule_Class("EnvironmentModule");
